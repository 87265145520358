import { reactive, watch } from 'vue';
import useAPI from './useAPI.js';

const api = useAPI();

const preorder = reactive({
    carId: null,
    catId: null,
    subcatId: null,
    anotherPersonName: '',
    anotherPersonPhone: '',
});


const refreshPreorder = () => {
    preorder.carId = parseInt(api.data.user.default_car);
    preorder.catId = null;
    preorder.subcatId = null;
    preorder.anotherPersonName = '';
    preorder.anotherPersonPhone = '';
};

console.log(preorder);
watch(preorder, () => {
    if (preorder.carId) {
        preorder.catId = null;
        preorder.subcatId = null;
    }
}, { deep: true });

watch(api.data.user, (newValue) => {
    if (newValue.id) {
        if ((preorder.carId == null) && newValue.default_car) { // null чтобы только при первом разе обновлялось, дальше будет 0
            preorder.carId = parseInt(newValue.default_car);
        }
    } else {
        preorder.carId = null;
        preorder.catId = null;
        preorder.subcatId = null;
        preorder.anotherPersonName = '';
        preorder.anotherPersonPhone = '';
    }
});

export default () => {
    return { preorder, refreshPreorder };
};