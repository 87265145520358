const config = {
  apiKey: "AIzaSyCcTc4WpmZD7IhQtW6-E6iXOY9RosnhBwY",
  authDomain: "spasmaster-fbab5.firebaseapp.com",
  projectId: "spasmaster-fbab5",
  storageBucket: "spasmaster-fbab5.appspot.com",
  messagingSenderId: "965280645157",
  appId: "1:965280645157:web:31250d264d90c87df2bfcc",
};

const vapidKey = "BMLV4NlXWL4WyFBn8xzDuQsneZmDg1j3S_xD2AldRRrvz2EDgVXR49xSf7hKDVxYkynuR0Boh_g5CQkaxZscl1c";

export { config, vapidKey };