<template>
  <ion-page>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="dismiss()">Отмена</ion-button>
        <ion-button @click="saveAndDismiss()">Готово</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-content fullscreen>
      <div class="avatar-editor-modal">
        <div class="avatar-editor-modal__preview" ref="preview" draggable :style="style" @touchstart="onTouchStart($event)" @touchmove="onTouchMove($event)" @touchend="onTouchEnd($event)"></div>
        <div class="avatar-editor-modal__zoom">
          <ion-range v-model="zoom" min="1" max="4" :step="0.1">
            <ion-icon slot="start" :icon="removeOutline" @click="zoomOut();"></ion-icon>
            <ion-icon slot="end" :icon="addOutline" @click="zoomIn();"></ion-icon>
          </ion-range>
        </div>
        <canvas ref="canvas" hidden></canvas>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
    // IonHeader,
    IonPage,
    IonToolbar,
    // IonTitle,
    IonButtons,
    IonIcon,
    IonButton,
    IonContent,
    IonRange,
    modalController,
} from '@ionic/vue';
import { addOutline, removeOutline } from 'ionicons/icons';
import useAPI from '../../api/useAPI.js';
import $ from 'jquery';

const MIN_ZOOM = 1;
const MAX_ZOOM = 4;
const ZOOM_STEP = 0.1;
const PREVIEW_SIZE = 300;

export default {
    components: {
        IonPage,
        IonToolbar,
        IonButtons,
        IonButton,
        IonContent,
        IonRange,
        IonIcon,
    },
    props: {
        /**
         * Data-URL картинки
         * @type {String}
         */
        url: {
            type: String,
            default: '',
        },
    },
    setup() {
        const api = useAPI();
        return { api, addOutline, removeOutline };
    },
    data: function () {
        return {
            originalImage: null,
            zoom: 1,
            x: 0.5,
            y: 0.5,
            initialWidth: 0,
            initialHeight: 0,
            initialZoom: 1,
            touchStart: null,
            positionBeforeDrag: null,
            zoomBeforeDrag: null,
        };
    },
    mounted() {
        this.originalImage = new Image();
        this.originalImage.onload = () => {
            this.initialWidth = this.originalImage.width;
            this.initialHeight = this.originalImage.height;
            if (this.initialWidth >= this.initialHeight) {
                if (this.initialHeight > PREVIEW_SIZE) {
                    this.initialZoom = PREVIEW_SIZE / this.initialHeight;
                }
            } else {
                if (this.initialWidth > PREVIEW_SIZE) {
                    this.initialZoom = PREVIEW_SIZE / this.initialWidth;
                }
            }
            this.x = (PREVIEW_SIZE - this.realWidth) / 2;
            this.y = (PREVIEW_SIZE - this.realHeight) / 2;
        }
        this.originalImage.src = this.url;
    },
    methods: {
        /**
         * Событие начала перетаскивания
         * @param  {Event} event Событие
         */
        onTouchStart(event) {
            const offset = $(this.$refs.preview).offset();
            const initialTouches = [];
            for (const touch of event.touches) {
                const initialTouch = {
                    left: (touch.clientX - offset.left),
                    top: (touch.clientY - offset.top),
                };
                initialTouches.push(initialTouch);
            }
            this.touchStart = initialTouches;
            this.positionBeforeDrag = { x: this.x, y: this.y };
            this.zoomBeforeDrag = this.zoom;
        },
        /**
         * Событие перетаскивания
         * @param  {Event} event Событие
         */
        onTouchMove(event) {
            if (!this.touchStart || 
                !this.touchStart.length || 
                !event.touches.length ||
                !this.positionBeforeDrag
            ) {
                return;
            }
            const offset = $(this.$refs.preview).offset();
            // Определим текущие позиции
            const currentTouches = [];
            for (let i = 0; (i < event.touches.length); i++) {
                const currentTouch = {
                    left: (event.touches[i].clientX - offset.left),
                    top: (event.touches[i].clientY - offset.top),
                };
                currentTouches.push(currentTouch);
            }
            // Определим смещения
            const delta = { x: 0, y: 0 };
            const deltasLength = Math.min(currentTouches.length, this.touchStart.length, 2);
            for (let i = 0; i < deltasLength; i++) {
                delta.x += currentTouches[i].left - this.touchStart[i].left;
                delta.y += currentTouches[i].top - this.touchStart[i].top;
            }
            delta.x /= deltasLength;
            delta.y /= deltasLength;

            this.x = this.positionBeforeDrag.x + delta.x;
            this.y = this.positionBeforeDrag.y + delta.y;
            this.adjustSize();
            
            let zoomMultiplier = 1;
            if (deltasLength >= 2) {
                // Определим изменение расстояния
                const initialLength = Math.sqrt(
                    Math.pow(this.touchStart[1].left - this.touchStart[0].left, 2) + 
                    Math.pow(this.touchStart[1].top - this.touchStart[0].top, 2)
                );
                const currentLength = Math.sqrt(
                    Math.pow(this.touchStart[1].left - this.touchStart[0].left, 2) + 
                    Math.pow(this.touchStart[1].top - this.touchStart[0].top, 2)
                );
                zoomMultiplier = (currentLength - initialLength) / initialLength;
                let newZoom = this.zoomBeforeDrag * zoomMultiplier;
                newZoom = Math.max(MIN_ZOOM, Math.min(newZoom, MAX_ZOOM));
                this.zoom = newZoom;
            }
            
        },
        /**
         * Событие окончания перетаскивания
         */
        onTouchEnd() {
            this.touchStart = null;
            this.positionBeforeDrag = null;
            this.zoomBeforeDrag = null;
        },
        /**
         * Увеличивает масштаб
         */
        zoomIn() {
            let zoom = this.zoom + ZOOM_STEP;
            zoom = Math.min(zoom, MAX_ZOOM);
            this.zoom = zoom;
        },
        /**
         * Уменьшает масштаб
         */
        zoomOut() {
            let zoom = this.zoom - ZOOM_STEP;
            zoom = Math.max(zoom, MIN_ZOOM);
            this.zoom = zoom;
        },
        /**
         * Приводит границы изображения к границам окна
         */
        adjustSize() {
            if (this.realWidth >= PREVIEW_SIZE) { // Если по ширине не помещается
                if (this.x > 0) {
                    this.x = 0; // Левая граница, this.x > 0
                }
                if (this.realWidth + this.x < PREVIEW_SIZE) { // Правая граница, this.x < 0
                    this.x = PREVIEW_SIZE - this.realWidth;
                }
            } else { // По ширине меньше рабочей области
                if (this.x < 0) {
                    this.x = 0; // Левая граница, this.x < 0
                }
                if (this.x > PREVIEW_SIZE - this.realWidth) { // Правая граница, this.x > 0
                    this.x = PREVIEW_SIZE - this.realWidth
                }
            }
            if (this.realHeight >= PREVIEW_SIZE) { // Если по ширине не помещается
                if (this.y > 0) {
                    this.y = 0; // Левая граница, this.y > 0
                }
                if (this.realHeight + this.y < PREVIEW_SIZE) { // Правая граница, this.y < 0
                    this.y = PREVIEW_SIZE - this.realHeight;
                }
            } else { // По ширине меньше рабочей области
                if (this.y < 0) {
                    this.y = 0; // Левая граница, this.y < 0
                }
                if (this.y > PREVIEW_SIZE - this.realHeight) { // Правая граница, this.y > 0
                    this.y = PREVIEW_SIZE - this.realHeight
                }
            }
        },
        /**
         * Закрытие окна
         */
        async dismiss() {
            await modalController.dismiss();
        },
        /**
         * Сохранить и закрыть окно
         */
        async saveAndDismiss() {
            const canvas = this.$refs.canvas;
            const ctx = this.$refs.canvas.getContext('2d');
            canvas.width = PREVIEW_SIZE;
            canvas.height = PREVIEW_SIZE;
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, PREVIEW_SIZE, PREVIEW_SIZE);
            ctx.drawImage(
                this.originalImage, 
                this.sx, 
                this.sy, 
                this.sw, 
                this.sh, 
                this.dx, 
                this.dy, 
                this.dw, 
                this.dh
            );
            const dataURL = canvas.toDataURL('image/jpeg', 0.85);
            const response = await this.api.method('user/set_avatar', { 
                'avatar': dataURL,
            });
            this.api.updateData(response);
            await modalController.dismiss();
        },
    },
    computed: {
        /**
         * X-смещение источника
         * @return {Number}
         */
        sx() {
            if (this.x < 0) {
                return Math.round(-1 * this.x / this.realWidth * this.initialWidth);
            }
            return 0;
        },
        /**
         * Y-смещение источника
         * @return {Number}
         */
        sy() {
            if (this.y < 0) {
                return Math.round(-1 * this.y / this.realHeight * this.initialHeight);
            }
            return 0;
        },
        /**
         * ширина источника
         * @return {Number}
         */
        sw() {
            return Math.round(Math.min(
                this.initialWidth, 
                PREVIEW_SIZE  / this.initialZoom / this.zoom
            ));
        },
        /**
         * Высота источника
         * @return {Number}
         */
        sh() {
            return Math.round(Math.min(
                this.initialHeight, 
                PREVIEW_SIZE  / this.initialZoom / this.zoom
            ));
        },
        /**
         * X-смещение получателя
         * @return {Number}
         */
        dx() {
            if (this.realWidth < PREVIEW_SIZE) {
                return Math.round(this.x);
            }
            return 0;
        },
        /**
         * Y-смещение получателя
         * @return {Number}
         */
        dy() {
            if (this.realHeight < PREVIEW_SIZE) {
                return Math.round(this.y);
            }
            return 0;
        },
        /**
         * ширина получателя
         * @return {Number}
         */
        dw() {
            return Math.round(Math.min(this.realWidth, PREVIEW_SIZE));
        },
        /**
         * Высота получателя
         * @return {Number}
         */
        dh() {
            return Math.round(Math.min(this.realHeight, PREVIEW_SIZE));
        },
        /**
         * Реальная ширина
         * @return {Number}
         */
        realWidth() {
            return (this.initialWidth * this.initialZoom * this.zoom);
        },
        /**
         * Реальная высота
         * @return {Number}
         */
        realHeight() {
            return (this.initialHeight * this.initialZoom * this.zoom);
        },
        /**
         * CSS-стиль предпросмотра
         */
        style() {
            const result = {};
            result['background-image'] = 'url(' + this.url + ')';
            result['background-position'] = this.x + 'px ' + this.y + 'px ';
            result['background-repeat'] = 'no-repeat';
            result.width = PREVIEW_SIZE + 'px';
            result.height = PREVIEW_SIZE + 'px';
            result['background-size'] = this.realWidth + 'px auto';
            return result;
        }
    },
    watch: {
        zoom() {
            window.setTimeout(() => {
                this.adjustSize();
            }, 0);
        }
    }
}
</script>

<style lang="scss" scoped>
.avatar-editor-modal {
    padding: 0 1rem;
    &__preview {
        border: 2px solid #ddd;
        margin: 0 auto 1rem;
        border-radius: 50%;
    }
}
</style>