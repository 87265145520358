import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import { config, vapidKey } from '../../firebase-web-config.js';

let messaging = null;

// console.log('Firebase config:', config)

/**
 * API для работы с Web уведомлениями
 */
export default {
    init() {
        if (messaging) {
            return messaging;
        }
        const app = initializeApp(config);
        messaging = getMessaging(app);
        return messaging;
    },
    /**
     * Функция регистрации работы с Web push-уведомлениями
     * @param {Function} <pre><code>function (notification)</code></pre> Функция обработки уведомлений
     * @return {String} Возвращает токен устройства
     */
    async register(callback) {
        const messaging = this.init();
        let permStatus = await window.Notification.requestPermission();
        if (permStatus !== 'granted') {
            throw new Error('Невозможно получить разрешение на отправку push-уведомлений');
        }

        const token = await getToken(messaging, { vapidKey });
        console.log('webPush registered ' + token);
        onMessage(messaging, (notification) => {
            console.log('webPush message received:', notification);
            if (callback) {
                callback(notification);
            }
            // new Notification(payload.notification.title, payload.notification);
        });
        return {
            type: 'web',
            token,
        };
    },


    /**
     * Убирает регистрацию
     */
    async unregister() {
        const messaging = this.init();
        const token = getToken(messaging);
        await deleteToken(messaging, token);
        console.log('webPush unregistered');
    },
};