<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="network-error-modal">
        <h1 class="network-error-modal__title">
          Ошибка соединения
        </h1>
        <div class="network-error-modal__description">
          Невозможно соединиться с сервером. Попробуйте еще раз. 
          Если ошибка повторится, вы можете позвонить нам на номер:
        </div>
        <div class="network-error-modal__phone">
          <a href="tel:%2B73433191319">(343) 319-1-319</a>
        </div>
        <div class="network-error-modal__reload">
          <ion-button expand="block" color="danger" @click="reload()">
            <ion-icon :icon="reloadOutline"></ion-icon> 
            Попробовать еще раз
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonIcon,
} from '@ionic/vue';
import { reloadOutline } from 'ionicons/icons';

export default {
    components: {
        IonPage,
        IonContent,
        IonIcon,
    },
    setup() {
        return { reloadOutline };
    },
    data: function () {
        return {
        }
    },
    methods: {
        reload() {
            window.location.reload();
        },
    },
}
</script>

<style lang="scss" scoped>
.network-error-modal {
    padding: 2rem;
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    flex-direction: column;
    text-align: center;
    &__description {
        margin: 2rem 0;
        font-size: 18px;
    }
    &__phone {
        font-size: 24px;
        a {
            color: var(--ion-color-primary-shade);
            text-decoration: none;
        }
    }
    &__reload {
        margin-top: 2rem;
        ion-icon {
            margin-right: .5rem;
        }
    }
}
</style>