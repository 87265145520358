import { PushNotifications } from '@capacitor/push-notifications';

/**
 * API для работы с нативными уведомлениями
 */
export default {
    /**
     * Функция регистрации работы с нативными push-уведомлениями
     * @param {Function} <pre><code>function (notification)</code></pre> Функция обработки уведомлений
     * @return {String} Возвращает токен устройства
     */
    async register(callback) {
        await PushNotifications.removeAllListeners();
        const p = new Promise((resolve) => {
            PushNotifications.addListener('registration', token => {
                console.log('nativePush registered ', token.value);
                resolve({
                    type: 'native',
                    token: token.value,
                });
            });
            PushNotifications.addListener('registrationError', err => {
                console.log('nativePush registrationError ' + err.error);
                throw err;
            });
            PushNotifications.addListener('pushNotificationReceived', notification => {
                console.log('nativePush pushNotificationReceived ', notification);
                if (callback) {
                    callback(notification);
                }
            });
            // PushNotifications.addListener('pushNotificationActionPerformed', action => {
            //     console.log('nativePush Push notification action performed', action);
            //     if (callback) {
            //         callback(action.notification);
            //     }
            // });
        })

        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== 'granted') {
            throw new Error('Невозможно получить разрешение на отправку push-уведомлений');
        }

        try {
            await PushNotifications.register();
            console.log('nativePush registered');
        } catch (e) {
            console.error(e);
        }

        return p;
    },


    /**
     * Убирает регистрацию
     */
    async unregister() {
        await PushNotifications.removeAllListeners();
        console.log('nativePush unregistered');
    },
};