/**
 * Ошибка HTTP-ответа JavaScript
 */
export default class HTTPStatusError
{
    /**
     * Конструктор класса
     * @param {String} message Сообщение об ошибке
     * @param {Number} code Код ошибки (код HTTP-ответа)
     */
    constructor(message, code)
    {
        this.message = message;
        this.code = code;
    }
}