<template>
  <ion-menu content-id="main-content" type="overlay" class="menu-mobile">
    <ion-content>
      <ion-menu-toggle auto-hide="false">
        <div @click="router.push('/Cabinet')">
          <div class="menu-mobile__header ion-padding-start">
            <ion-avatar class="menu-mobile__avatar">
              <img v-if="api.data.user.avatar" :src="api.data.user.avatar" alt="" @click="$emit('avatarClick')">
            </ion-avatar>
            <div class="menu-mobile__user-data">
              <div class="menu-mobile__user-name">
                {{ api.data.user.full_name || 'Новый пользователь' }}
              </div>
              <div class="menu-mobile__user-phone">
                {{ formatPhone(api.data.user.phone) }}
              </div>
            </div>
          </div>
        </div>
      </ion-menu-toggle>
      <ion-list class="menu-mobile__list">
        <ion-menu-toggle auto-hide="false" v-for="(item, index) in items" :key="index">
          <ion-item 
            v-if="item.external"
            class="menu-mobile__item" 
            lines="none" 
            :class="{ 'menu-mobile__item_selected': selectedIndex === index }"
            :href="item.url"
          >
            <ion-icon :icon="item.icon" class="menu-mobile__image"></ion-icon>
            <ion-label>
              {{ item.title }}
            </ion-label>
            <ion-badge v-if="item.counter" color="primary">
              {{ item.counter }}
            </ion-badge>
          </ion-item>
          <ion-item 
            v-else
            class="menu-mobile__item" 
            lines="none" 
            :class="{ 'menu-mobile__item_selected': selectedIndex === index }"
            router-direction="root" 
            :router-link="item.url" 
            @click="!item.external && (selectedIndex = index)" 
          >
            <ion-icon :icon="item.icon" class="menu-mobile__image"></ion-icon>
            <ion-label>
              {{ item.title }}
            </ion-label>
            <ion-badge v-if="item.counter" color="primary">
              {{ item.counter }}
            </ion-badge>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import { 
    IonAvatar,
    IonContent, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonMenu, 
    IonMenuToggle,
    IonIcon,
    IonBadge,
    // IonRouterLink,
} from '@ionic/vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import formatPhone from '../../mixins/formatPhone.js';
import useAPI from '../../api/useAPI.js';

export default {
    components: {
        IonAvatar,
        IonContent, 
        IonItem, 
        IonLabel, 
        IonList, 
        IonMenu, 
        IonMenuToggle,
        IonIcon,
        IonBadge,
    },
    props: {
        /**
         * Пункты меню
         * @type {Object[]}
         */
        items: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const api = useAPI();
        const router = useRouter();
        const selectedIndex = ref(0);
        const path = window.location.pathname;
        if (path !== undefined) {
            selectedIndex.value = props.items.findIndex(item => item.url.toLowerCase() === path.toLowerCase());
        }
        return { selectedIndex, router, formatPhone, api };
    },
}
</script>

<style lang="scss" scoped>
.menu-mobile {
    &__header {
        background: var(--ion-color-primary);
        padding: 1rem;
        display: flex;
        align-items: center;
        color: var(--body-color);
    }
    &__user-data {
        margin-left: 1rem;
    }
    &__user-name {
        font-size: 1.05rem;
    }
    &__user-phone {
        margin-top: 5px;
        font-size: 0.95rem;
    }
    &__item {
        // display: flex;
        // align-items: center;
        &_selected {
            --ion-item-background: #f4f4f4;
            @media (prefers-color-scheme: dark) {
                --ion-item-background: #000;
            }
        }
    }
    &__image {
        margin-right: 1rem;
        color: var(--ion-color-primary-shade);
        -webkit-filter: drop-shadow( 3px 3px 2px rgba(black, .1));
        filter: drop-shadow( 3px 3px 2px rgba(black, .1));
    }
    &__avatar {
        img {
            background: white;
        }
    }
}
</style>