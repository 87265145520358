import { isPlatform  } from '@ionic/vue';
import nativePush from './nativePush';
import webPush from './webPush';

/**
 * API для работы с уведомлениями в зависимости от окружения
 */
export default {
    /**
     * Выбирает систему рассылки в зависимости от окружения
     * @return {Object}
     */
    spawn() {
        if (isPlatform('cordova') || isPlatform('capacitor')) {
            return nativePush;
        } else {
            return webPush;
        }
    },


    /**
     * Функция регистрации работы с нативными push-уведомлениями
     * @param {Function} <pre><code>function (notification)</code></pre> Функция обработки уведомлений
     * @return {String} Возвращает токен устройства
     */
    async register(callback) {
        return this.spawn().register(callback);
    },


    /**
     * Убирает регистрацию
     */
    async unregister() {
        return this.spawn().unregister();
    },
};