import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'Main',
        component: () => import ('../views/Main/SpasmasterMain.vue'),
    },
    {
        path: '/Order/:serviceType',
        component: () => import ('../views/Order/NewOrder.vue'),
    },
    {
        path: '/Order/:serviceType/from/:id',
        component: () => import ('../views/Order/NewOrder.vue'),
    },
    {
        path: '/OrderStatus/:id',
        component: () => import ('../views/Order/SpasmasterOrder.vue'),
    },
    {
        path: '/OrderHistory/',
        component: () => import ('../views/OrderHistory/OrderHistory.vue'),
    },
    {
        path: '/BecomePartner/',
        component: () => import ('../views/BecomePartner/BecomePartner.vue'),
    },
    {
        path: '/Cabinet',
        component: () => import ('../views/Cabinet/SpasmasterCabinet.vue'),
    },
    {
        path: '/Info/:id',
        component: () => import ('../views/Info/SpasmasterInfo.vue')
    },
    {
        path: '/News',
        component: () => import ('../views/News/SpasmasterNews.vue')
    },
    {
        path: '/PaymentTypes',
        component: () => import ('../views/PaymentTypes/PaymentTypes.vue')
    },
    {
        path: '/Cars',
        component: () => import ('../views/Cars/SpasmasterCars.vue')
    },
    {
        path: '/Cars/New',
        component: () => import ('../views/Cars/SpasmasterCars.vue')
    },
    {
        path: '/Cars/New/from/:fromOrderId',
        component: () => import ('../views/Cars/SpasmasterCars.vue')
    },
    {
        path: '/MyExecutors',
        component: () => import ('../views/MyExecutors/MyExecutors.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
