import { ref, reactive } from 'vue';
import { toastController, isPlatform  } from '@ionic/vue';
import HTTPStatusError from './HTTPStatusError.js';

let abortController = null;

const data = reactive({
    user: {
        'full_name': 'Новый пользователь',
        phone: '',
        avatar: '',
    },
    infos: {},
    news: {},
    cars: {},
    categories: {},
    brands: {},
    payments: {},
    serviceTypes: {},
    myExecutors: {},
    minPaymentSum: 0,
    serverTime: '0000-00-00 00:00:00',
});

const networkError = ref(false);

const isApp = (isPlatform('cordova') || isPlatform('capacitor'));
let INIT_URL;
if (!isApp && /(localhost)|(app\.spasmaster\/)/gi.test(document.URL)) {
    INIT_URL = 'http://app.spasmaster/api/';
} else {
    INIT_URL = 'https://app.spasmaster.ru/api/';
}

/**
 * Абстрактный сервис-провайдер данных API
 */
export default () => {
    /**
     * Обработчик ошибки
     * @param {String} message Сообщение
     * @param {Number} duration Длительность показа
     */
    const errorHandler = async (message, duration = 2000) => {
        const toast = await toastController.create({message, duration})
        toast.present();
    };

    /**
     * Запрашивает серверные данные
     * @param {String} method Метод API
     * @param {Object} request Данные для запроса
     * @return {Object} 
     */
    const method = async (methodName = '', request = {}, nativeHTTPErrorHandler = true) => {
        if (!request) {
            request = {};
        }
        request.phone = (data.user && data.user.phone) 
            || window.localStorage.getItem('phone');
        request.password = (data.user && data.user.password) 
            || window.localStorage.getItem('password');

        const url = INIT_URL + (methodName ? (methodName + '/') : '');
        let response, result;
        abortController = new AbortController();
        try {
            response = await fetch(url, {
                method: 'post',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify(request),
                signal: abortController.signal,
            });
            result = await response.json();
        } catch (e) {
            if (e.name == 'AbortError') {
                console.log('Request to ' + url + ' aborted')
            } else {
                networkError.value = true;
            }
            return null;
        }

        try {
            if (response.status >= 400 && response.status < 600) {
                if (result.errors && result.errors[0]) {
                    const err = result.errors[0];
                    throw new HTTPStatusError(err.message, err.code);
                } else {
                    throw new HTTPStatusError('Ошибка доступа к серверу', response.status);
                }
            }
            return result.response;
        } catch (e) {
            if (nativeHTTPErrorHandler) {
                errorHandler(e.message);
                return null;
            } else {
                throw e;
            }
        }
    };


    /**
     * Прерывает текущий метод
     */
    const abort = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    
    /**
     * Обновляет пользователя
     * @param {Object} userData Данные пользователя
     */
    const updateUser = (userData) => {
        // const oldUser = JSON.parse(JSON.stringify(data.user));
        // const user = Object.assign({}, oldUser, userData);
        for (const key in userData) {
            if (userData[key] === null) {
                data.user[key] = ref(null);
            } else if (typeof userData[key] === 'object') {
                data.user[key] = reactive(userData[key]);
            } else {
                data.user[key] = ref(userData[key]);
            }
        }
        // data.user = reactive(user);
    };

    
    /**
     * Обновляет информационные страницы
     * @param {Object} infosData Данные информационных страниц
     * @param {Boolean} clear Очистить предыдущие данные
     */
    const updateOrders = (ordersData, clear = false) => {
        if (clear) {
            data.user.orders = reactive({});
        }
        if (Object.values(ordersData).length || ordersData.length) {
            for (const key in ordersData) {
                const orderData = ordersData[key];
                data.user.orders[key] = reactive(orderData);
            }
        } else {
            data.user.orders = reactive({});
        }
    };

    
    /**
     * Обновляет информационные страницы
     * @param {Object} infosData Данные информационных страниц
     */
    const updateInfos = (infosData) => {
        for (const key in infosData) {
            const infoData = infosData[key];
            if (!data.infos[key]) {
                data.infos[key] = reactive(infoData);
            } else if (!data.infos[key].description && infoData.description) {
                data.infos[key].description = ref(infoData.description);
            }
        }
    };

    
    /**
     * Обновляет новости
     * @param {Object} newsData Данные новостей
     */
    const updateNews = (newsData) => {
        for (const key in newsData) {
            const itemData = newsData[key];
            if (!data.news[key]) {
                data.news[key] = reactive(itemData);
            } else if (!data.news[key].description && itemData.description) {
                data.news[key].description = ref(itemData.description);
            }
        }
    };

    
    /**
     * Обновляет марки/модели
     * @param {Object} brandsData Данные марок/моделей
     */
    const updateBrands = (brandsData) => {
        for (const key in brandsData) {
            const brandData = brandsData[key];
            if (!data.brands[key]) {
                data.brands[key] = reactive(brandData);
            } else if (!(
                    data.brands[key].models && 
                    Object.values(data.brands[key].models).length
                ) && 
                brandData.models && 
                Object.values(brandData.models).length
            ) {
                data.brands[key].models = reactive(brandData.models);
            }
        }
    };


    /**
     * Обновляет автомобили
     * @param {Object} carsData Данные автомобилей
     */
    const updateCars = (carsData) => {
        for (const key in carsData) {
            const carData = carsData[key];
            data.cars[key] = reactive(carData);
        }
    };


    /**
     * Обновляет автомобили
     * @param {Object} paymentsData Данные автомобилей
     */
    const updatePayments = (paymentsData) => {
        for (const key in paymentsData) {
            const paymentData = paymentsData[key];
            data.payments[key] = reactive(paymentData);
        }
    };

    
    /**
     * Обновляет типы услуг
     * @param {Object} serviceTypesData Данные типов услуг
     */
    const updateServiceTypes = (serviceTypesData) => {
        for (const key in serviceTypesData) {
            const serviceType = serviceTypesData[key];
            if (!data.serviceTypes[key]) {
                data.serviceTypes[key] = reactive(serviceType);
            } else {
                if (!(
                    data.serviceTypes[key].jobTypes && 
                    Object.values(data.serviceTypes[key].jobTypes).length
                ) && 
                    serviceType.jobTypes && 
                    Object.values(serviceType.jobTypes).length
                ) {
                    data.serviceTypes[key].jobTypes = reactive(serviceType.jobTypes);
                }
                if (!(
                    data.serviceTypes[key].preferences && 
                    Object.values(data.serviceTypes[key].preferences).length
                ) && 
                    serviceType.preferences && 
                    Object.values(serviceType.preferences).length
                ) {
                    data.serviceTypes[key].preferences = reactive(serviceType.preferences);
                }
            }
        }
    };

    
    /**
     * Обновляет "моих мастеров"
     * @param {Object} myExecutorsData Данные "моих мастеров"
     */
    const updateMyExecutors = (myExecutorsData) => {
        data.myExecutors = reactive(myExecutorsData);
    };

    
    /**
     * Обновляет данные
     * @param {Object} userData Данные пользователя
     */
    const updateData = (newData) => {
        for (const key of Object.keys(newData)) {
            switch (key) {
                case 'user':
                    updateUser(newData.user);
                    break;
                case 'infos':
                    updateInfos(newData.infos);
                    break;
                case 'news':
                    updateNews(newData.news);
                    break;
                case 'myExecutors':
                    updateMyExecutors(newData.myExecutors);
                    break;
                case 'brands':
                    updateBrands(newData.brands);
                    break;
                case 'serviceTypes':
                    updateServiceTypes(newData.serviceTypes);
                    break;
                default:
                    if (typeof newData[key] === 'object') {
                        data[key] = reactive(newData[key]);
                    } else {
                        data[key] = ref(newData[key]);
                    }
                    break;
            }
        }
    };

    return { 
        INIT_URL,
        method, 
        abort,
        data, 
        updateData, 
        updateCars,
        updateOrders,
        updatePayments,
        networkError, 
        isApp, 
        errorHandler 
    };
};